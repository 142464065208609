<template>
  <EditModal ref="upsertProviderSchemeForm">
    <template #btn-text></template>
    <template #formTitle></template>
    <template #form>
      <h4 class="mb-5">
        <slot name="formTitle"
        >{{ upsertTitle }} Pension Provider &sol; Scheme
        </slot>
      </h4>
      <div v-if="this.processingData">Loading</div>
      <div v-else>
        <Form
            :validation-schema="schema"
            autocomplete="off"
            class="w-100 text-left"
            @submit="saveForm"
        >
          <TextInput
              :value="this.idToString"
              label=""
              name="provider_id"
              type="hidden"
          />
          <div class="row">
            <div class="col-6">
              <strong>Provider &sol; Scheme Name:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.name"
                  label=""
                  name="name"
                  placeholder="Not Set"
              />
            </div>
            <div class="col-6">
              <strong>Email:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.pensionProviderEmail"
                  label=""
                  name="email"
                  placeholder="Not Set"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Provider Address 1:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.address_1"
                  label=""
                  name="address_1"
                  placeholder=""
              />
            </div>
            <div class="col-3">
              <strong>Town:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.town"
                  label=""
                  name="town"
                  placeholder=""
              />
            </div>
            <div class="col-3">
              <strong>County:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.county"
                  label=""
                  name="county"
                  placeholder=""
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Provider Address 2:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.address_2"
                  label=""
                  name="address_2"
                  placeholder=""
              />
            </div>
            <div class="col-3">
              <strong>Country:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.country"
                  label=""
                  name="country"
                  placeholder=""
              />
            </div>
            <div class="col-3">
              <strong>Postcode:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.postcode"
                  label=""
                  name="postcode"
                  placeholder=""
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Provider Address 3:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.address_3"
                  label=""
                  name="address_3"
                  placeholder=""
              />
            </div>
            <div class="col-2">
              <strong>Region:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.region"
                  label=""
                  name="region"
                  placeholder=""
              />
            </div>
            <div class="col-4">
              <strong>Telephone:</strong><br/>
              <TextInput
                  :value="this.updatedDataScheme.number"
                  label=""
                  name="number"
                  placeholder=""
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Communication By Post:</strong><br/>
              <div class="form-check form-check-inline">
                <input
                    id="communicationYes"
                    v-model="this.updatedDataScheme.communicationByPost"
                    class="form-check-input"
                    name="requires_communication_by_post"
                    type="radio"
                    value="1"
                />
                <label class="form-check-label" for="communicationYes"
                >Yes</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                    id="communicationNo"
                    v-model="this.updatedDataScheme.communicationByPost"
                    class="form-check-input"
                    name="requires_communication_by_post"
                    type="radio"
                    value="0"
                />
                <label class="form-check-label" for="communicationNo">No</label>
              </div>
            </div>
            <div class="col-6">
              <strong>Requires Wet Signature:</strong><br/>
              <div class="form-check form-check-inline">
                <input
                    id="signatureYes"
                    v-model="this.updatedDataScheme.requiresWetSignature"
                    class="form-check-input"
                    name="requires_wet_signature"
                    type="radio"
                    value="1"
                />
                <label class="form-check-label" for="signatureYes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                    id="signatureNo"
                    v-model="this.updatedDataScheme.requiresWetSignature"
                    class="form-check-input"
                    name="requires_wet_signature"
                    type="radio"
                    value="0"
                />
                <label class="form-check-label" for="signatureNo">No</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong>Requires Encryption:</strong><br/>
              <div class="form-check form-check-inline">
                <input
                    id="EncryptionYes"
                    v-model="this.updatedDataScheme.requiresEncryption"
                    aria-selected="true"
                    class="form-check-input"
                    name="requires_encryption"
                    type="radio"
                    value="1"
                />
                <label class="form-check-label" for="EncryptionYes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                    id="EncryptionNo"
                    v-model="this.updatedDataScheme.requiresEncryption"
                    class="form-check-input"
                    name="requires_encryption"
                    type="radio"
                    value="0"
                />
                <label class="form-check-label" for="EncryptionNo">No</label>
              </div>
            </div>
            <div class="col-6">
              <strong>Response Goes To Customer:</strong><br/>
              <div class="form-check form-check-inline">
                <input
                    id="ResponseYes"
                    v-model="this.updatedDataScheme.responseGoesToCustomer"
                    class="form-check-input"
                    name="response_goes_to_customer"
                    type="radio"
                    value="1"
                />
                <label class="form-check-label" for="ResponseYes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                    id="ResponseNo"
                    v-model="this.updatedDataScheme.responseGoesToCustomer"
                    class="form-check-input"
                    name="response_goes_to_customer"
                    type="radio"
                    value="0"
                />
                <label class="form-check-label" for="ResponseNo">No</label>
              </div>
            </div>
          </div>
          <button class="fabric-btn fabric-btn-submit">Save</button>
        </Form>
      </div>
    </template>
  </EditModal>
</template>
<script>
import * as Yup from "yup";

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "UpsertProviderSchemes",
  props: {
    providerId: {
      type: Number,
      default: null
    },
    isShown: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updated", "close"],
  data: () => ({
    processingData: false,
    updatedDataScheme: {
      name: null,
      pensionProviderEmail: null,
      communicationByPost: null,
      requiresWetSignature: null,
      requiresEncryption: null,
      responseGoesToCustomer: null,
      address_1: null,
      address_2: null,
      address_3: null,
      town: null,
      county: null,
      postcode: null,
      country: null,
      region: null,
      number: null
    }
  }),
  setup() {
    const schema = Yup.object().shape({
      name: Yup.string()
          .required("required")
          .nullable(),
      email: Yup.string()
          .required("required")
          .email()
          .nullable(),
      address_1: Yup.string()
          .required("required")
          .nullable(),
      region: Yup.string()
          .required("required")
          .matches(
              /^(\+?\d{1,3}|\d{1,4})$/,
              "Region must be in number"
          )
          .nullable(),
      number: Yup.string()
          .required("required")
          .min(10, "Number must be 10 digits long")
          .nullable()
    });
    return {
      schema
    };
  },
  watch: {
    isShown: function () {
      if (!this.isShown) {
        this.updateData(null);
        return;
      }
      if (this.providerId) {
        //Edit Modal
        this.processingData = true;
        this.loadProvider();
        this.$refs.upsertProviderSchemeForm.handleClick();
      } else {
        //Add Modal
        this.updateData(null);
        this.$refs.upsertProviderSchemeForm.handleClick();
      }
    }
  },
  computed: {
    ...mapGetters(["getProviderUpdatedData"]),
    idToString() {
      return this.providerId ? this.providerId.toString() : "";
    },
    upsertTitle() {
      return this.providerId ? "Edit" : "Add";
    },
    upsertFeedback() {
      return this.providerId ? "Updated" : "Added";
    }
  },
  methods: {
    ...mapActions([
      "getProviderData",
      "updatePensionProvider",
      "addPensionProvider",
      "fetchProviderList"
    ]),
    ...mapMutations(["setSchemeUpdatedData", "setLoading", "setNotification"]),
    async loadProvider() {
      let miscResponse = await this.getProviderData({
        id: this.providerId
      }).catch(err => {
        this.processingData = false;
        this.$refs.upsertProviderSchemeForm.handleClose();
        this.setNotification({
          show: true,
          type: "error",
          message: "Pension Provider Error!",
          content: this.formulateErrorString(err)
        });
      });
      this.updateData(miscResponse);
      if (this.updatedDataScheme.id === this.providerId) {
        this.processingData = false;
      }
    },
    async saveForm(params) {
      this.processingData = true;
      //Setup Params
      params.requires_communication_by_post = this.updatedDataScheme.communicationByPost;
      params.requires_wet_signature = this.updatedDataScheme.requiresWetSignature;
      params.requires_encryption = this.updatedDataScheme.requiresEncryption;
      params.response_goes_to_customer = this.updatedDataScheme.responseGoesToCustomer;

      let pensionProviderResponse;
      if (this.providerId) {
        //Edit Mode
        pensionProviderResponse = await this.updatePensionProvider({
          tempParams: params
        }).catch(err => {
          this.processingData = false;
          this.$refs.upsertProviderSchemeForm.handleClose();
          this.setNotification({
            show: true,
            type: "error",
            message: "Pension Provider Error!",
            content: this.formulateErrorString(err)
          });
        });
      } else {
        //Add Mode
        pensionProviderResponse = await this.addPensionProvider({
          tempParams: params
        }).catch(err => {
          this.processingData = false;
          this.$refs.upsertProviderSchemeForm.handleClose();
          this.setNotification({
            show: true,
            type: "error",
            message: "Pension Provider Error!",
            content: this.formulateErrorString(err)
          });
        });
        await this.fetchProviderList().catch(err => console.log(err));
      }
      if ([200, 201].includes(pensionProviderResponse[0].status)) {
        this.setNotification({
          show: true,
          type: "success",
          message: `Details ${this.upsertFeedback}!`
        });
      }
      if ([200, 201].includes(pensionProviderResponse[1].status)) {
        this.setNotification({
          show: true,
          type: "success",
          message: `Address ${this.upsertFeedback}!`
        });
      }
      if ([200, 201].includes(pensionProviderResponse[2].status)) {
        this.setNotification({
          show: true,
          type: "success",
          message: `Contact ${this.upsertFeedback}!`
        });
      }
      this.processingData = false;
      this.$refs.upsertProviderSchemeForm.handleClose();
    },
    updateData(response) {
      if (!response) {
        Object.keys(this.updatedDataScheme).forEach(
            i => (this.updatedDataScheme[i] = null)
        );
        return;
      }
      let data = response.data,
          address = response.data.address[response.data.address.length - 1];
      // contact = response.data.contact[response.data.contact.length - 1];
      this.updatedDataScheme.id = data.id ?? null;
      this.updatedDataScheme.name = data.name ?? null;
      this.updatedDataScheme.pensionProviderEmail = data.email ?? null;
      this.updatedDataScheme.communicationByPost =
          data.requires_communication_by_post ?? null;
      this.updatedDataScheme.requiresWetSignature =
          data.requires_wet_signature ?? null;
      this.updatedDataScheme.requiresEncryption =
          data.requires_encryption ?? null;
      this.updatedDataScheme.responseGoesToCustomer =
          data.response_goes_to_customer ?? null;
      if (address) {
        this.updatedDataScheme.address_1 = address.address_1 ?? null;
        this.updatedDataScheme.address_2 = address.address_2 ?? null;
        this.updatedDataScheme.address_3 = address.address_3 ?? null;
        this.updatedDataScheme.town = address.town ?? null;
        this.updatedDataScheme.county = address.county ?? null;
        this.updatedDataScheme.postcode = address.postcode ?? null;
        this.updatedDataScheme.country = address.country ?? null;
      }
      // if (contact) {
      //   this.updatedDataScheme.region = data.region ?? null;
      this.updatedDataScheme.number = data.current_phone_number ?? null;
      // }
    }
  }
};
</script>

<template>
  <div class="container">
    <TitleHeader>Pension Providers &amp; Schemes</TitleHeader>
    <div class="card">
      <div class="card-header">
        <!--        <ul class="nav nav-tabs card-header-tabs float-right">-->
        <!--          <li class="nav-item">-->
        <!--            <a class="nav-link" href="#" @click="addProvider">-->
        <!--              <icon-->
        <!--                  :data="add"-->
        <!--                  class="icon"-->
        <!--                  height="15"-->
        <!--                  role="button"-->
        <!--                  width="15"-->
        <!--              />-->
        <!--              Provider &sol; Scheme-->
        <!--            </a>-->
        <!--          </li>-->
        <!--          <li class="nav-item">-->
        <!--            <EditModal ref="attachProviderEmployerForm" class="-inline">-->
        <!--              <template #btn-text>-->
        <!--                <a class="nav-link" href="#" @click="attachProviderEmployer">-->
        <!--                  <icon-->
        <!--                      :data="add"-->
        <!--                      class="icon"-->
        <!--                      height="15"-->
        <!--                      role="button"-->
        <!--                      width="15"-->
        <!--                  />-->
        <!--                  Provider &sol; Employer-->
        <!--                </a>-->
        <!--              </template>-->
        <!--              <template #form>-->
        <!--                <h4 class="mb-4">-->
        <!--                  Attach Provider to Employer-->
        <!--                </h4>-->
        <!--                <Form-->
        <!--                    :validation-schema="schema"-->
        <!--                    class="w-100 text-left"-->
        <!--                    @submit="saveForm"-->
        <!--                >-->
        <!--                  <TextInput-->
        <!--                      label="Employer"-->
        <!--                      name="name_of_employer"-->
        <!--                      type="text"-->
        <!--                  />-->
        <!--                  <label>Pension provider</label>-->
        <!--                  <FuzzySearch-->
        <!--                      :keys="['name']"-->
        <!--                      :options="getProviders(true)"-->
        <!--                      @selected="handleFuzzySelect"-->
        <!--                  />-->
        <!--                  <DatePicker-->
        <!--                      label="Date from"-->
        <!--                      name="employed_from"-->
        <!--                  />-->
        <!--                  <DatePicker-->
        <!--                      label="Date to"-->
        <!--                      name="employed_to"-->
        <!--                  />-->
        <!--                  <button class="fabric-btn fabric-btn-submit">Attach</button>-->
        <!--                </Form>-->
        <!--              </template>-->
        <!--            </EditModal>-->
        <!--          </li>-->
        <!--        </ul>-->
      </div>
      <div class="card-body -no-border">
        <PensionProviderList @edit="handleEditProvider"/>
        <UpsertProviderSchemes
            ref="editProvider"
            :isShown="showProviderForm"
            :providerId="editProviderId"
            @closed="handleClose"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import PensionProviderList from "@/components/lists/PensionProviderList";
import UpsertProviderSchemes from "@/components/form/UpsertProviderSchemes";
import add from "@fa/solid/plus-circle.svg";
// import FuzzySearch from "@/components/search/FuzzySearch";
// import DatePicker from "@/components/form/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";

dayjs.extend(customParseFormat);

export default {
  name: "PensionProviders",
  components: {
    PensionProviderList,
    UpsertProviderSchemes,
    // FuzzySearch,
    // DatePicker,
  },
  data: () => ({
    editProviderId: null,
    showProviderForm: false,
    providerId: null,
    add
  }),
  watch: {
    async getPusherNotification(param) {
      switch (param.type) {
        case 'finder-event-received':
          await this.fetchProviderList().catch(err => console.log(err));
          break;
      }
    }
  },
  setup() {
    const schema = Yup.object().shape({
      name_of_employer: Yup.string().required(),
      employed_from: Yup.string().required(),
      employed_to: Yup.string().required()
    });
    return {
      schema
    };
  },
  computed: {
    ...mapGetters(["getProviders"])
  },
  methods: {
    ...mapActions(["fetchProviderList", "attachProvider"]),
    ...mapMutations(["setLoading", "setNotification"]),
    async saveForm(params) {
      params.providerId = this.providerId;
      params.employed_from = params.employed_from
          ? dayjs(params.employed_from).format("YYYY-MM-DD")
          : null;
      params.employed_to = params.employed_to
          ? dayjs(params.employed_to).format("YYYY-MM-DD")
          : null;

      let response = await this.attachProvider(params).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Attachment error!",
          content: this.formulateErrorString(err)
        });
      });

      if (response.status == 200) {
        this.$refs.attachProviderEmployerForm.show = false;
        this.setNotification({
          show: true,
          type: "success",
          message: "Provider attached successfully!"
        });
      }
    },
    handleFuzzySelect({result, submitted}) {
      if (submitted) {
        this.providerId = result.id;
      }
    },
    handleEditProvider(providerId) {
      this.editProviderId = providerId;
      this.showProviderForm = true;
    },
    handleClose() {
      this.editProviderId = null;
      this.showProviderForm = false;
    },
    addProvider() {
      this.editProviderId = null;
      this.showProviderForm = true;
    },
    attachProviderEmployer() {
      console.log('attach provider');
    }
  },
  async beforeMount() {
    this.setLoading({bool: true});
    await this.fetchProviderList().catch(err => console.log(err));
    this.setLoading({bool: false});
  }
};
</script>
<style type="text/css">
.icon {
  color: dimgrey;
  margin-right: 10px;
  padding-bottom: 2px;
}
</style>

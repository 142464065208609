<template>
  <div class="card mb-4">
    <div class="card-body">
      <TextInput
          class="pr-1 m-0"
          label="Search Pension Provider &sol; Schemes"
          name="searchProvidersInput"
          type="text"
          @updated="handleUpdate"
      />
    </div>
  </div>
  <div>
    <div
        v-if="this.getProviders()"
        class="providers-tree list-group list-group-root well"
    >
      <providers-tree
          v-for="provider in this.computedProviders"
          :id="provider.id"
          :key="provider.id"
          :children="provider.children"
          :depth="0"
          :providerName="provider.name"
          @edit="handleEditProvider"
      ></providers-tree>
    </div>
    <div v-else class="mt-3">No records found!</div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ProvidersTree from "@/components/lists/items/ProvidersTree";

export default {
  name: "PensionProviderList",
  components: {
    ProvidersTree
  },
  emits: ["edit"],
  data: () => ({
    providersSearchTerm: ""
  }),
  computed: {
    ...mapGetters([
      "getProviders",
      "getProvidersDataTree",
      "getProvidersHashTable",
    ]),
    computedProviders() {
      //  use the pre-populated data tree
      if (!this.providersSearchTerm) return this.getProvidersDataTree;

      const providers = this.getProviders()
      const hashTable = this.getProvidersHashTable;
      const result = [];
      const searchTerms = this.providersSearchTerm.toLowerCase().split(" ").filter(term => term && term !== " ");

      //  keep track of any hits to prevent a provider being added more than once if a later child is found
      const foundProviders = {};

      providers.forEach(provider => {
        const name = provider.name.toLowerCase();
        const isHit = searchTerms.filter(term => name.includes(term) || term.includes(name)).length
        if (isHit) {
          //     //  already a root parent?
          //     if (!provider.parent_id)
          //     {
          if (!foundProviders[provider.id]) {
            result.push(hashTable[provider.id]);
            foundProviders[provider.id] = true;
          }
          //     } else {
          //       let targetProvider = provider;
          //
          //       //  find root parent
          //       while(hashTable[targetProvider.parent_id].parent_id)
          //       {
          //         targetProvider = hashTable[targetProvider.parent_id];
          //       }
          //
          //       //  check the root parent hasn't already been added to the tree
          //       if (!foundProviders[targetProvider.parent_id])
          //       {
          //         result.push(hashTable[targetProvider.parent_id]);
          //         foundProviders[targetProvider.parent_id] = true;
          //       }
          //     }
        }
      });

      return result;
    }
  },
  methods: {
    handleUpdate(response) {
      this.debounce(() => this.providersSearchTerm = response);
    },
    handleEditProvider(providerId) {
      this.$emit("edit", providerId);
    },
  }
};
</script>
<style type="text/css">
.providers-tree {
  text-align: left;
}

.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}

.list-group.list-group-root .list-group {
  margin-right: 0;
  margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
  padding: 10px 0 0 0;
  margin: 0;
}

.list-group.list-group-root .list-group-item:first-child {
  margin-bottom: 0;
}

.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}
</style>
